import { DashBoardType } from "@/types";
import { GalleryHorizontalEnd } from "lucide-react";

const dashBoardToolsCarousel: DashBoardType = {
  title: "Carousel",
  slug: "/admin/tools/carousel",
  icon: GalleryHorizontalEnd,
};

export default dashBoardToolsCarousel;
