import { DashBoardType } from "@/types";
import { Wrench } from "lucide-react";

const dashBoardTools: DashBoardType = {
  title: "Tools",
  slug: "/",
  icon: Wrench,
};

export default dashBoardTools;
