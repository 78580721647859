const sharedRouteConfig = {
  home: "/",
  category: "/c",
  posts: "/p",
  editorPosts: "/admin/editor/posts",
  adminComments: "/admin/comments",
  adminCategories: "/admin/categories",
  adminContact: "/admin/contact",
  bookmarks: "/bookmarks",
  contact: "/contact",
  dashboard: "/admin/dashboard",
  search: "/search",
  about: "/about",
  terms: "/terms",
  policy: "/policy",
  settings: "/settings",
  images: "/images",
  videos: "/videos",
  videosCategory: "/videos/c",
  videoSearch: "/videos/search",
  categories: "/categories",
};

export default sharedRouteConfig;
