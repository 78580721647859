import { DashBoardType } from "@/types";
import { Tag } from "lucide-react";

const dashBoardCategories: DashBoardType = {
  title: "Categories",
  slug: "/admin/categories",
  icon: Tag,
};

export default dashBoardCategories;
