import { DashBoardType } from "@/types";
import { Image } from "lucide-react";

const dashBoardImages: DashBoardType = {
  title: "Images",
  slug: "/admin/images",
  icon: Image,
};

export default dashBoardImages;
