import { DashBoardType } from "@/types";
import { BarChart2 } from "lucide-react";

const dashBoardMain: DashBoardType = {
  title: "Dashboard",
  slug: "/admin/dashboard",
  icon: BarChart2,
};

export default dashBoardMain;
