import { DashBoardType } from "@/types";
import { MessageCircleIcon } from "lucide-react";

const dashBoardComments: DashBoardType = {
  title: "Comments",
  slug: "/admin/comments",
  icon: MessageCircleIcon,
};

export default dashBoardComments;
