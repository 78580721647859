import { DashBoardType } from "@/types";
import { MailCheck } from "lucide-react";

const dashBoardContact: DashBoardType = {
  title: "Contact",
  slug: "/admin/contact",
  icon: MailCheck,
};

export default dashBoardContact;
