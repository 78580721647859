import { DashBoardType } from "@/types";
import { PlaySquare } from "lucide-react";

const dashBoardVideos: DashBoardType = {
  title: "Videos",
  slug: "/admin/videos",
  icon: PlaySquare,
};

export default dashBoardVideos;
