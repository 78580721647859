const sharedLoginConfig = {
  // Login form
  title: "Login",
  description: "Please sign in to continue.",
  or: "or",
  close: "Close",
  // Social login form
  google: "Sign in with Google",
  facebook: "Sign in with Facebook",
  github: "Sign in with Github",

  // Email login form
  email: "Sign in with magic link",
  sendButton: "Send magic link",
  emailRequiredError: "Email is required.",
  passwordRequiredError: "Password is required",
  confirmPasswordRequiredError: "Confirm password is required",
  emailSent: "Email sent.",
  error: "Error occurred.",

  signedOut: "Successfully signed out",

  signIn: "Sign In",
  signInTagLine: " Sign in to access your account",
  signUp: "Sign Up",

  signedIn: "Successfully signed in",

  passwordReset: "Password Reset",
  setPasswordReset: "Set a New Password",
};

export default sharedLoginConfig;
