import { DashBoardType } from "@/types";
import { Drum } from "lucide-react";

const dashBoardPlayground: DashBoardType = {
  title: "Playground",
  slug: "/admin/playground",
  icon: Drum,
};

export default dashBoardPlayground;
